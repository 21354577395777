@include keyframes(ori-card-loading) {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

@include keyframes(ori-fade-in) {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@include keyframes(ori-fade-in-left) {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


@include keyframes(ori-fade-in-right) {
    from {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


// @include keyframes(ori-fade-in-up) {
//     from {
//         opacity: 0;
//         -webkit-transform: translate3d(0, 100%, 0);
//         transform: translate3d(0, 100%, 0);
//     }

//     to {
//         opacity: 1;
//         -webkit-transform: translate3d(0, 0, 0);
//         transform: translate3d(0, 0, 0);
//     }
// }


@include keyframes(ori-fade-out) {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


// @include keyframes(ori-fade-out-left) {
//     from {
//         opacity: 1;
//     }

//     to {
//         opacity: 0;
//         -webkit-transform: translate3d(-100%, 0, 0);
//         transform: translate3d(-100%, 0, 0);
//     }
// }


// @include keyframes(ori-fade-out-right) {
//     from {
//         opacity: 1;
//     }

//     to {
//         opacity: 0;
//         -webkit-transform: translate3d(100%, 0, 0);
//         transform: translate3d(100%, 0, 0);
//     }
// }


// @include keyframes(ori-fade-out-down) {
//     from {
//         opacity: 1;
//     }

//     to {
//         opacity: 0;
//         -webkit-transform: translate3d(0, 100%, 0);
//         transform: translate3d(0, 100%, 0);
//     }
// }


@include keyframes(ori-fade-out-up) {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}


@include keyframes(ori-zoom-in) {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}


// @include keyframes(ori-zoom-out) {
//     from {
//         opacity: 1;
//     }

//     50% {
//         opacity: 0;
//         -webkit-transform: scale3d(0.3, 0.3, 0.3);
//         transform: scale3d(0.3, 0.3, 0.3);
//     }

//     to {
//         opacity: 0;
//     }
// }


@include keyframes(ori-translate-down) {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
