$font-family-primary: "Roboto", sans-serif;
$font-family-secondary: "Roboto", sans-serif;

$font-thin: 300;
$font-normal: 400;
$font-bold: 500;
$font-ultra-bold: 700;

$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-xxl: 30px;

$border-radius-base: 3px;
